import React, { useContext, useState } from "react";
import { navigate } from "gatsby";
import SEO from "../seo";
import {
  MixdownWrapper,
  LeftWrapper,
  MixdownTitle,
  SmallText,
  Inline,
  Stack,
} from "../../StyledComponents/mixdown.styles";
import { SubmitButton } from "../SubmitButton";
import { BackButton } from "../BackButton";
import { InputWithLabel } from "../InputWithLabel";
import { FormContext } from "../../context/formContext";
import { useForm } from "react-hook-form";
import { useSubmitOrder } from "../../services/useSubmitOrder";

export const productionMap = {
  false: {
    priceMultiplier: 1,
    title: "unplugged",
  },
  true: {
    priceMultiplier: 2,
    title: "electronic",
  },
};

const MixdownContactMobile = ({ data }) => {
  const [extraAddressInfo, setExtraAddressInfo] = useState("");

  const {
    fullName,
    setFullName,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    instagram,
    setInstagram,
    streetAndHouseNumber,
    setStreetAndHouseNumber,
    city,
    setCity,
    zip,
    setZip,
    country,
    setCountry,
    finalFormData,
  } = useContext(FormContext);

  const { submitOrder } = useSubmitOrder();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { contactTitleLeft, contactDescriptionLeft, contactTitleRight } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

  const onSubmit = () => {
    submitOrder(finalFormData);
  };

  return (
    <>
      <SEO title="SPANDAU20 Mixdown" />
      <MixdownWrapper gap="48px">
        <Inline justifyContent="flex-start">
          <BackButton />
        </Inline>
        <form onSubmit={handleSubmit(onSubmit)}>
          <LeftWrapper gap="48px">
            <Stack gap="24px">
              <Stack gap="24px">
                <MixdownTitle>{contactTitleLeft}</MixdownTitle>
                <SmallText>{contactDescriptionLeft}</SmallText>
              </Stack>
              <Stack alignItems="flex-start" gap="12px">
                <InputWithLabel
                  name="Full name"
                  text="Full name"
                  type="text"
                  value={fullName}
                  onChange={e => setFullName(e.target.value)}
                  register={register}
                  errors={errors}
                />
                <InputWithLabel
                  name="E-Mail address"
                  text="E-Mail address"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  register={register}
                  errors={errors}
                />
                <InputWithLabel
                  name="Phone number"
                  text="Phone number"
                  type="phone"
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                  register={register}
                  errors={errors}
                />
                <InputWithLabel
                  name="Instagram (Optional)"
                  text="Instagram (Optional)"
                  type="text"
                  value={instagram}
                  onChange={e => setInstagram(e.target.value)}
                  register={register}
                  errors={errors}
                  required={false}
                />
              </Stack>
            </Stack>
            <Stack gap="24px">
              <MixdownTitle>{contactTitleRight}</MixdownTitle>
              <Stack gap="12px">
                <InputWithLabel
                  name="Street and house number"
                  text="Street and house number"
                  type="text"
                  value={streetAndHouseNumber}
                  onChange={e => setStreetAndHouseNumber(e.target.value)}
                  register={register}
                  errors={errors}
                />
                <InputWithLabel
                  name="City"
                  text="City"
                  type="text"
                  value={city}
                  onChange={e => setCity(e.target.value)}
                  register={register}
                  errors={errors}
                />
                <InputWithLabel
                  name="ZIP"
                  text="ZIP"
                  type="text"
                  value={zip}
                  onChange={e => setZip(e.target.value)}
                  register={register}
                  errors={errors}
                />
                <InputWithLabel
                  name="Country"
                  text="Country"
                  type="text"
                  value={country}
                  onChange={e => setCountry(e.target.value)}
                  register={register}
                  errors={errors}
                />
              </Stack>
            </Stack>
            <Inline justifyContent="flex-end">
              <SubmitButton>GET A QUOTE</SubmitButton>
            </Inline>

            <input
              name="fix"
              type="text"
              width={123}
              value={extraAddressInfo}
              onChange={e => setExtraAddressInfo(e.target.value)}
              style={{ visibility: "hidden" }}
            />
          </LeftWrapper>
        </form>
      </MixdownWrapper>
    </>
  );
};

export default MixdownContactMobile;
