import { useState } from "react";
import { navigate } from "gatsby";

// define url based on development or production environment
const baseUrl =
  process.env.NODE_ENV === "development" ? "http://localhost:8888" : "";

// fetch pricing data from the API with a POST request on http://localhost:8888/.netlify/functions/server/calculate and an object containing the pricing data
export const useSubmitOrder = () => {
  const submitOrder = async formData => {
    await fetch(`${baseUrl}/.netlify/functions/send-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then(res => {
      console.log(res.status);
      if (res.status === 200) {
        navigate("/mixdown/thanks");
      } else {
        navigate("/mixdown/error");
      }
    });
  };

  return { submitOrder };
};
