import React, { useContext, useState } from "react";
import { graphql, navigate } from "gatsby";

import { useWindowSize } from "../../lib/useWindowSize";
import { screenSizes } from "../../styles/variables";

import SEO from "../../components/seo";
import {
  MixdownWrapper,
  LeftWrapper,
  MixdownTitle,
  SmallText,
  RightWrapper,
} from "../../StyledComponents/mixdown.styles";
import { SubmitButton } from "../../components/SubmitButton";
import { BackButton } from "../../components/BackButton";
import { InputWithLabel } from "../../components/InputWithLabel";
import { FormContext } from "../../context/formContext";
import { useForm } from "react-hook-form";
import MixdownContactMobile from "../../components/mixdownMobilePages/ContactMobile";
import { useSubmitOrder } from "../../services/useSubmitOrder";

const MixdownForm = ({ data }) => {
  const [extraAddressInfo, setExtraAddressInfo] = useState("");
  const [isBeingSubmitted, setIsBeingSubmitted] = useState(false);
  const {
    fullName,
    setFullName,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    instagram,
    setInstagram,
    streetAndHouseNumber,
    setStreetAndHouseNumber,
    city,
    setCity,
    zip,
    setZip,
    country,
    setCountry,
    finalFormData,
  } = useContext(FormContext);
  const { width } = useWindowSize();

  const { submitOrder } = useSubmitOrder();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const isBelowTablet = width <= screenSizes.tablet;

  const { contactTitleLeft, contactDescriptionLeft, contactTitleRight } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

  const onSubmit = () => {
    setIsBeingSubmitted(true);
    submitOrder(finalFormData);
  };

  return (
    <>
      {!isBelowTablet ? (
        <>
          <SEO title="SPANDAU20 Mixdown" />
          <MixdownWrapper>
            <form
              style={{
                display: "flex",
                height: "100%",
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <LeftWrapper>
                <MixdownTitle>{contactTitleLeft}</MixdownTitle>
                <SmallText>{contactDescriptionLeft}</SmallText>
                <div
                  style={{
                    display: "flex",
                    gap: 11,
                    flexDirection: "column",
                    marginTop: 32,
                  }}
                >
                  <InputWithLabel
                    name="full_name"
                    text="Full name"
                    type="text"
                    value={fullName}
                    onChange={e => setFullName(e.target.value)}
                    register={register}
                    errors={errors}
                  />
                  <InputWithLabel
                    name="email_address"
                    text="E-Mail address"
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    register={register}
                    errors={errors}
                  />
                  <InputWithLabel
                    name="phone_number"
                    text="Phone number"
                    type="phone"
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                    register={register}
                    errors={errors}
                  />
                  <InputWithLabel
                    name="instagram_optional"
                    text="Instagram (Optional)"
                    type="text"
                    value={instagram}
                    onChange={e => setInstagram(e.target.value)}
                    register={register}
                    required={false}
                    errors={errors}
                  />
                </div>
              </LeftWrapper>
              <RightWrapper>
                <div>
                  <MixdownTitle>{contactTitleRight}</MixdownTitle>
                  <div
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                      display: "flex",
                      gap: 11,
                      marginTop: 86,
                    }}
                  >
                    <InputWithLabel
                      name="street_and_house_number"
                      text="Street and house number"
                      type="text"
                      value={streetAndHouseNumber}
                      onChange={e => setStreetAndHouseNumber(e.target.value)}
                      register={register}
                      errors={errors}
                    />
                    <div style={{ display: "flex", gap: 4 }}>
                      <InputWithLabel
                        name="city"
                        text="City"
                        type="text"
                        value={city}
                        onChange={e => setCity(e.target.value)}
                        register={register}
                        errors={errors}
                      />
                      <InputWithLabel
                        name="zip"
                        text="ZIP"
                        type="text"
                        value={zip}
                        onChange={e => setZip(e.target.value)}
                        register={register}
                        errors={errors}
                      />
                    </div>
                    <InputWithLabel
                      name="country"
                      text="Country"
                      type="text"
                      value={country}
                      onChange={e => setCountry(e.target.value)}
                      register={register}
                      errors={errors}
                    />
                  </div>
                </div>

                <input
                  name="fix"
                  type="text"
                  width={123}
                  value={extraAddressInfo}
                  onChange={e => setExtraAddressInfo(e.target.value)}
                  style={{ visibility: "hidden" }}
                />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <BackButton />
                  <SubmitButton disabled={isBeingSubmitted}>ORDER</SubmitButton>
                </div>
              </RightWrapper>
            </form>
          </MixdownWrapper>
        </>
      ) : (
        <MixdownContactMobile data={data} />
      )}
    </>
  );
};

export default MixdownForm;

export const query = graphql`
  query ContactQuery {
    allFile(filter: { sourceInstanceName: { eq: "mixdownContact" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              contactTitleLeft
              contactDescriptionLeft
              contactTitleRight
            }
          }
        }
      }
    }
  }
`;
